import {Owner, UncleanDetail, UncleanRecord} from '../types';
import {auditProApi} from './apiUtils';

export const find = (id: number): Promise<Owner> => auditProApi.getWithJsonResponse(
  `/owners/${id}`
);

export const update = (id: number, ownerRequest: Owner): Promise<Owner> => auditProApi.patchWithJsonResponse(
  `/owners/${id}`,
  {
    body: JSON.stringify(ownerRequest)
  }
);

export const findDirty = async (): Promise<UncleanDetail[]> => auditProApi.getWithJsonResponse(
  `/owners/dirty`
);

export const findDirtyForCounty = async (county: string): Promise<UncleanDetail[]> => auditProApi.getWithJsonResponse(
  `/owners/dirty/${county}`
);

export const searchDirty = (county: String,
                            localUnit: String): Promise<UncleanRecord[]> => auditProApi.getWithJsonResponse(
  `/owners/dirty/search?county=${county}&localUnit=${localUnit}`
);

export const skip = async (id: number) => auditProApi.patchWithJsonResponse(
  `/owners/${id}/skip`
);

export const clean = async (id: number) => auditProApi.patchWithJsonResponse(
  `/owners/${id}/clean`
);