import {ReactNode, useState} from 'react';

import AuditProAppContext from './AuditProAppContext';
import {AuditProConfiguration} from '../types';

type Props = {
  children: ReactNode
}

const AuditPropAppProvider = ({children}: Props) => {
  const [configuration, setConfiguration] = useState<AuditProConfiguration>({currentYears: []});

  return (
    <AuditProAppContext.Provider value={{configuration, setConfiguration}}>
      {children}
    </AuditProAppContext.Provider>
  );
};

export default AuditPropAppProvider;