import {Badge, Card, CardHeader} from 'reactstrap';

import {CustomTable, ExportBatchItem} from '@reasoncorp/kyber-js';

import {openAndFocus} from '../../util';

type Props = {
  items: ExportBatchItem[]
}

const ExportTable = ({
                       items
                     }: Props) => {

  const renderBadge = (exportBatchItem: ExportBatchItem) => {
    let color;
    if (exportBatchItem.status === 'RECEIVED') {
      color = 'success';
    } else if (exportBatchItem.status === 'PROCESSING') {
      color = 'primary';
    } else {
      color = 'danger';
    }
    return <Badge color={color}>
      {exportBatchItem.status}
    </Badge>;
  };

  const tableProps = {
    headers: [
      {sortKey: 'reasonId', title: 'Reason ID', className: 'text-center align-middle'},
      {sortKey: 'parcelNumber', title: 'Parcel ID', className: 'text-center align-middle'},
      {sortKey: 'county', title: 'County', className: 'text-center align-middle'},
      {sortKey: 'city', title: 'City/Township', className: 'text-center align-middle'},
      {sortKey: 'address', title: 'Address', className: 'text-center align-middle'},
      {sortKey: 'owner', title: 'Owner', className: 'text-center align-middle'},
      {sortKey: 'majorReason', title: 'Major Reason', className: 'text-center align-middle'},
      {sortKey: 'status', title: 'Status', className: 'text-center align-middle'},
      {sortKey: 'errorMessage', title: 'Error Message', className: 'align-middle'}
    ],
    items,
    noResultsMessage: 'No Exports available to display in report.',
    renderRow: (exportBatchItem: ExportBatchItem) => {
      return (
        <tr key={exportBatchItem.exemptionId}>
          <td role="link">
            <a href={`/exemptions/${exportBatchItem.exemptionId}`}
               onClick={((e) => openAndFocus(e, `/exemptions/${exportBatchItem.exemptionId}`, 'exemptionView'))}>
              {exportBatchItem.reasonId}
            </a>
          </td>
          <td className="align-left">{exportBatchItem.parcelNumber}</td>
          <td className="align-left">{exportBatchItem.county}</td>
          <td className="align-left">{exportBatchItem.city}</td>
          <td className="align-left text-nowrap">{exportBatchItem.address}</td>
          <td className="align-left">{exportBatchItem.ownerNames}</td>
          <td className="align-left">{exportBatchItem.majorReason}</td>
          <td className="text-center">
            {renderBadge(exportBatchItem)}
          </td>
          <td>{exportBatchItem.errorMessage}</td>
        </tr>
      );
    }
  };

  return <Card>
    <CardHeader>Export List</CardHeader>
    <CustomTable {...tableProps}/>
  </Card>;
};

export default ExportTable;
