import {AppBar, SOMLogo, User, UserProfile} from '@reasoncorp/kyber-js';

import {ssoApi} from '../api';
import config from '../config';

type Props = {
  currentUser?: User | null
  isAdmin: boolean
}

const AuditProAppBar = ({
                          currentUser,
                          isAdmin
                        }: Props) => {
  const handleProfileClick = () => {
    window.location.href = `${config.sso.webUrl}/user-profile`;
  };

  const handleSignOut = async () => {
    await ssoApi.signOut();
  };

  const renderUserProfile = currentUser ? () =>
    <UserProfile handleProfileClick={handleProfileClick} handleSignOut={handleSignOut}/> : undefined;

  let routes = [
    {name: 'Search', to: '/', exact: true},
    {name: 'Clean', to: '/clean'}
  ];

  if (isAdmin) {
    routes = [
      ...routes,
      {name: 'Exports', to: '/exports'},
      {name: 'Reports', to: '/reports'}
    ];
  }

  return (
    <AppBar brandLink={`${config.sso.webUrl}/dashboard`}
            brandImage={SOMLogo}
            brandImageAlt="ProSuite"
            appName="AuditPro"
            organizationName="The Department of Treasury"
            environmentName={config.envName !== 'prod' ? config.envName : undefined}
            routes={routes}
            renderUserProfile={renderUserProfile}/>
  );
};

export default AuditProAppBar;
