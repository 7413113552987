import {useEffect, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Form, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {union} from 'lodash';

import {
  ConfirmationModal,
  CustomTable,
  FormikSelect,
  PendingExport,
  ProgressIndicator,
  SelectTableCell,
  SelectTableCellData,
  useAlerts
} from '@reasoncorp/kyber-js';
import * as messages from '../messages';
import {exportsApi} from '../api';

type Props = {
  numberSelected: number,
  total: number
  onExport: () => void
}

const ExportControls = ({
                          numberSelected,
                          total,
                          onExport
                        }: Props) => {
  return (
    <Row>
      <Col className="d-flex justify-content-end align-items-center">
        <h5 className="mb-0 mr-2">Total to Export: {numberSelected}/{total}</h5>
        <Button color="success"
                size="sm"
                disabled={numberSelected === 0}
                onClick={onExport}>
          Export
        </Button>
      </Col>
    </Row>
  );
};


const Exports = () => {
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false
  });
  const [isExporting, setIsExporting] = useState(false);
  const [selectedMajorReason, setSelectedMajorReason] = useState('');
  const [pendingExports, setPendingExports] = useState<PendingExport[]>([]);
  const [filteredPendingExports, setFilteredPendingExports] = useState<PendingExport[]>([]);
  const [showExportConfirmationModal, setShowExportConfirmationModal] = useState(false);
  const numSelected = filteredPendingExports.filter(pendingExport => pendingExport.selected).length;
  const majorReasons = union(pendingExports.map(pendingExport => pendingExport.majorReason)).sort();

  const handleSelectAll = (data: SelectTableCellData) => {
    setFilteredPendingExports(filteredPendingExports.map(pendingExport => data.itemIds.includes(pendingExport.id) ? {
      ...pendingExport,
      selected: !data.prevSelection
    } : pendingExport));
  };

  const handleItemSelect = (data: SelectTableCellData) => {
    setFilteredPendingExports(filteredPendingExports.map(pendingExport => pendingExport.id === data.itemId ? {
      ...pendingExport,
      selected: !data.prevSelection
    } : pendingExport));
  };

  const tableProps = {
    className: 'mb-0',
    items: filteredPendingExports,
    noResultsMessage: 'No exemptions match for export.',
    headers: [
      {selectKey: 'selected', dataIdKey: 'id', onChange: handleSelectAll},
      {title: 'Reason ID', sortKey: 'reasonId', className: 'text-center align-middle'},
      {title: 'Parcel ID', sortKey: 'parcelNumber', className: 'text-center align-middle'},
      {title: 'County', sortKey: 'county'},
      {title: 'City/Township', sortKey: 'township'},
      {title: 'Owner', sortKey: 'owner'},
      {title: 'Address', sortKey: 'address'},
      {title: 'Major Reason', sortKey: 'majorReason'}
    ],
    initialSort: {sortKey: 'reasonId', direction: 'asc' as const},
    renderRow: (pendingExport: PendingExport) => {
      return (
        <tr key={pendingExport.id}>
          <SelectTableCell itemId={pendingExport.id}
                           selected={pendingExport.selected === undefined ? false : pendingExport.selected}
                           onChange={handleItemSelect}/>
          <td className="text-center align-middle">{pendingExport.reasonId}</td>
          <td className="text-center align-middle">{pendingExport.parcelNumber}</td>
          <td className="align-middle">{pendingExport.county}</td>
          <td className="align-middle">{pendingExport.township}</td>
          <td className="align-middle">{pendingExport.owner}</td>
          <td className="align-middle">{pendingExport.address}</td>
          <td className="align-middle">{pendingExport.majorReason}</td>
        </tr>
      );
    },
    paginatorConfig: {perPage: 250}
  };

  const handleExport = async () => {
    setIsExporting(true);
    const exemptionIds = filteredPendingExports.filter(pendingExport => pendingExport.selected)
      .map(pendingExport => pendingExport.exemptionId);
    try {
      await exportsApi.createBatch(exemptionIds);

      setSelectedMajorReason('');
      const pendingExports = await exportsApi.findPending();
      setPendingExports(pendingExports);
      showSuccessAlert(messages.EXPORTS_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.EXPORTS_FAILURE);
    }
    setIsExporting(false);
    setShowExportConfirmationModal(false);
  };

  useEffect(() => {
      const loadData = async () => {
        try {
          const pendingExports = await exportsApi.findPending();
          setPendingExports(pendingExports);
          setFilteredPendingExports(pendingExports);
          setLoadingState({loadError: false, loading: false});
        } catch (error) {
          showErrorAlert(messages.EXPORTS_LOAD_FAILURE);
          setLoadingState({loading: false, loadError: true});
        }
      };

      loadData().then();
    },
    [showErrorAlert]
  );

  useEffect(() => {
      const filteredPendingExports = selectedMajorReason ?
        pendingExports.filter(pendingExport => pendingExport.majorReason.trim() === selectedMajorReason)
          .map(pendingExport => ({...pendingExport, selected: false}))
        :
        pendingExports.map(pendingExport => ({...pendingExport, selected: false}));
      setFilteredPendingExports(filteredPendingExports);
    },
    [pendingExports, selectedMajorReason]
  );

  return (
    <Container fluid>
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loadError && !loadingState.loading &&
        <>
          <ExportControls numberSelected={numSelected}
                          total={filteredPendingExports.length}
                          onExport={() => setShowExportConfirmationModal(true)}/>
          <Card className="my-3">
            <CardHeader className="d-flex justify-content-between align-items-center">
            <span>
              Export Queue
            </span>
              <Formik initialValues={{majorReason: ''}}
                      onSubmit={(values: {majorReason: string}, formikHelpers: FormikHelpers<{majorReason: string}>) => {
                        setSelectedMajorReason(values.majorReason);
                        formikHelpers.setSubmitting(false);
                      }}>
                {(formikProps) => (
                  <Form autoComplete="off">
                    <FormikSelect name="majorReason"
                                  aria-required={true}
                                  onChange={formikProps.submitForm}
                                  style={{width: 'auto'}}
                                  formGroupClass="mb-0">
                      <option value="">Select Major Reason</option>
                      {majorReasons.map(majorReason => {
                        return (
                          <option key={majorReason} value={majorReason}>{majorReason}</option>
                        );
                      })}
                    </FormikSelect>
                  </Form>
                )}
              </Formik>
            </CardHeader>
            <CustomTable  {...tableProps}/>
          </Card>
          <ExportControls numberSelected={numSelected}
                          total={pendingExports.length}
                          onExport={() => setShowExportConfirmationModal(true)}/>
          <ConfirmationModal isOpen={showExportConfirmationModal}
                             size="lg"
                             title="Export Records"
                             confirmButtonText="Yes"
                             cancelButtonText="No"
                             confirmCallback={() => handleExport()}
                             cancelCallback={() => setShowExportConfirmationModal(false)}
                             confirmButtonDisabled={isExporting}
                             cancelButtonDisabled={isExporting}>
            <p>
              Are you sure you want to export {numSelected} records?
            </p>
          </ConfirmationModal>
        </>
      }
    </Container>
  );
};

export default Exports;