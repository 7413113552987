import {RestApiHelper, SsoSpringConfig, SsoSpringRestApi} from '@reasoncorp/kyber-js';

import config from '../config';

export const ssoApi = new SsoSpringRestApi(new SsoSpringConfig({...config.sso}));

export const auditProApi = new RestApiHelper({
  path: config.apiHost,
  unauthorizedHandler: ssoApi.redirectToLogin,
  lastActivityCookieName: config.sso.lastActivityCookieName,
  lastActivityCookieDomain: config.sso.lastActivityCookieDomain,
  defaultFetchConfig: {
    credentials: 'include'
  }
});