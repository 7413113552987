import {ExportBatchLite, ExportBatchReport} from '@reasoncorp/kyber-js';

import {auditProApi} from './apiUtils';
import {CleanedRecordReport} from '../types';

export const findCleanedRecords = (year: number): Promise<CleanedRecordReport> => auditProApi.getWithJsonResponse(
  `/reports/cleaned-records/${year}`
);

export const downloadCleanedRecordsReport = (year: number) => auditProApi.getWithBlobResponse(
  `/reports/cleaned-records/${year}/download`
);

export const findExportBatch = (batchId: number): Promise<ExportBatchReport> => auditProApi.getWithJsonResponse(
  `/reports/exports/batches/${batchId}`
);

export const findExportBatches = (year: number): Promise<ExportBatchLite[]> => auditProApi.getWithJsonResponse(
  `/reports/exports/batches?year=${year}`
);

export const downloadExportBatchReport = (batchId: number) => auditProApi.getWithBlobResponse(
  `/reports/exports/batches/${batchId}/download`
);