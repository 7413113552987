import {Card, CardHeader, Col, Row} from 'reactstrap';

import {CustomTable, ExportBatchLite, ExportSummary} from '@reasoncorp/kyber-js';

import {formatDateTime} from '../../util';

type Props = {
  selectedBatch: ExportBatchLite
  exportSummaries: ExportSummary[]
};

const ExportSummaryTable = ({
                              selectedBatch,
                              exportSummaries
                            }: Props) => {
  const totals = {
    totalFailed: 0,
    totalSuccessful: 0,
    totalDuplicate: 0,
    totalProcessing: 0,
    totalCount: 0,
    companyName: 0,
    failureToRespond: 0,
    nonResident: 0,
    notOwnerOccupied: 0,
    partialExemption: 0,
    rentalProperty: 0,
    unqualifiedLand: 0
  };

  exportSummaries.forEach(exportSummary => {
    totals.totalFailed += exportSummary.totalFailed;
    totals.totalSuccessful += exportSummary.totalSuccessful;
    totals.totalDuplicate += exportSummary.totalDuplicate;
    totals.totalProcessing += exportSummary.totalProcessing;
    totals.totalCount += exportSummary.totalCount;
    totals.companyName += exportSummary.companyName;
    totals.failureToRespond += exportSummary.failureToRespond;
    totals.nonResident += exportSummary.nonResident;
    totals.notOwnerOccupied += exportSummary.notOwnerOccupied;
    totals.partialExemption += exportSummary.partialExemption;
    totals.rentalProperty += exportSummary.rentalProperty;
    totals.unqualifiedLand += exportSummary.unqualifiedLand;
  });

  const tableProps = {
    noResultsMessage: 'No Exports available to display in report.',
    items: exportSummaries,
    headers: [
      {sortKey: 'countyName', title: 'County', className: 'text-center align-middle'},
      {sortKey: 'companyName', title: 'Company Name', className: 'text-center align-middle'},
      {sortKey: 'failureToRespond', title: 'Failure To Respond', className: 'text-center align-middle'},
      {sortKey: 'nonResident', title: 'Non-Resident', className: 'text-center align-middle'},
      {sortKey: 'notOwnerOccupied', title: 'Not Owner Occupied', className: 'text-center align-middle'},
      {sortKey: 'partialExemption', title: 'Partial Exemption', className: 'text-center align-middle'},
      {sortKey: 'rentalProperty', title: 'Rental Property', className: 'text-center align-middle'},
      {sortKey: 'unqualifiedLand', title: 'Unqualified Land', className: 'text-center align-middle'},
      {sortKey: 'totalSuccessful', title: 'County Totals', className: 'text-center align-middle'}
    ],
    renderRow: (exportSummary: ExportSummary) => {
      return (
        <tr key={exportSummary.countyName} className="text-center">
          <td>{exportSummary.countyName}</td>
          <td>{exportSummary.companyName}</td>
          <td>{exportSummary.failureToRespond}</td>
          <td>{exportSummary.nonResident}</td>
          <td>{exportSummary.notOwnerOccupied}</td>
          <td>{exportSummary.partialExemption}</td>
          <td>{exportSummary.rentalProperty}</td>
          <td>{exportSummary.unqualifiedLand}</td>
          <td>{exportSummary.totalSuccessful}</td>
        </tr>
      );
    },
    renderFooter: () => {
      return (
        <tr className="text-center text-dark bg-light font-weight-bold">
          <td>Totals</td>
          <td>{totals.companyName}</td>
          <td>{totals.failureToRespond}</td>
          <td>{totals.nonResident}</td>
          <td>{totals.notOwnerOccupied}</td>
          <td>{totals.partialExemption}</td>
          <td>{totals.rentalProperty}</td>
          <td>{totals.unqualifiedLand}</td>
          <td>{totals.totalSuccessful}</td>
        </tr>
      );
    }
  };

  return <Card>
    <CardHeader>
      <Row>
        <Col>
          Export Summary {formatDateTime(selectedBatch.createdAt)}
        </Col>
        <Col className="justify-content-end d-flex">
          {totals.totalProcessing} processing, {totals.totalSuccessful} successful, {totals.totalDuplicate} duplicates, {totals.totalFailed} failed of {totals.totalCount} Total Exports
        </Col>
      </Row>
    </CardHeader>
    <CustomTable {...tableProps}/>
  </Card>;
};

export default ExportSummaryTable;
