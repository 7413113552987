import {formatBoolean, formatDate, formatDateTime, formatNumber} from './formatting';
import openAndFocus from './openAndFocus';
import {Exemption} from '../types';

const isExemptionInCurrentYears = (exemption: Exemption | undefined, currentYears: number[]) => exemption !== undefined && currentYears.includes(exemption.year);

const isNotNullOrBlank = (val: string | undefined | null | number) => val !== null && val !== undefined && val !== '';

export {
  formatBoolean,
  formatDate,
  formatDateTime,
  formatNumber,
  isExemptionInCurrentYears,
  isNotNullOrBlank,
  openAndFocus
};