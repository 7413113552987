import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, CardHeader, Col, Container, Row} from 'reactstrap';

import {BreadcrumbsNav, CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {Owner, UncleanRecord} from '../types';
import {ownerApi} from '../api';
import * as messages from '../messages';
import {OwnerCard} from '../components/shared';

const CleanOwner = () => {
  const {county, localUnit} = useParams() as {county: string, localUnit: string};
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false
  });
  const [uncleanRecords, setUncleanRecords] = useState<UncleanRecord[]>([]);
  const [owner, setOwner] = useState<Owner | undefined>(undefined);

  const breadcrumbs = [
    {text: 'Clean', route: '/clean'},
    {text: county, route: `/clean/${county}`},
    {text: localUnit, active: true}
  ];

  const handleRecordClick = async (uncleanRecord: UncleanRecord) => {
    try {
      const owner = await ownerApi.find(uncleanRecord.ownerId);
      setOwner(owner);
    } catch (e) {
      showErrorAlert(messages.OWNER_LOAD_FAILURE);
    }
  };

  const loadDirtyOwners = async () => {
    try {
      const dirtyRecords = await ownerApi.searchDirty(county, localUnit);
      setUncleanRecords(dirtyRecords);
    } catch (error) {
      showErrorAlert(messages.UNCLEAN_LOAD_FAILURE);
    }
  };

  useEffect(() => {
    const loadDirtyRecords = async () => {
      try {
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loading: true}));
        const dirtyRecords = await ownerApi.searchDirty(county, localUnit);
        setUncleanRecords(dirtyRecords);
      } catch (error) {
        showErrorAlert(messages.UNCLEAN_LOAD_FAILURE);
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loadError: true}));
      } finally {
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loading: false}));
      }
    };
    loadDirtyRecords().then();
  }, [county, localUnit, showErrorAlert]);

  const tableProps = {
    headers: [],
    items: uncleanRecords,
    noResultsMessage: 'No records to clean',
    renderRow: (uncleanRecord: UncleanRecord) => {
      return (
        <tr key={uncleanRecord.reasonId}
            className="cursor-pointer"
            onClick={() => handleRecordClick(uncleanRecord)}>
          <td className="align-left pl-3">{uncleanRecord.reasonId}</td>
        </tr>
      );
    }
  };

  const handleClean = async (ownerRequest: Owner) => {
    try {
      await ownerApi.update(owner?.id ?? 0, ownerRequest);
      await ownerApi.clean(owner?.id ?? 0);
      setOwner(undefined);
      showSuccessAlert(messages.RECORD_CLEAN_SUCCESS);

      await loadDirtyOwners();
    } catch (e) {
      showErrorAlert(messages.RECORD_CLEAN_FAILURE);
    }
  };

  const handleSkip = async () => {
    try {
      await ownerApi.skip(owner?.id ?? 0);
      setOwner(undefined);
      showSuccessAlert(messages.RECORD_SKIP_SUCCESS);

      await loadDirtyOwners();
    } catch (e) {
      showErrorAlert(messages.RECORD_SKIP_FAILURE);
    }
  };

  const handleSave = async (ownerRequest: Owner) => {
    try {
      await ownerApi.update(owner?.id ?? 0, ownerRequest);
      showSuccessAlert(messages.OWNER_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.OWNER_SAVE_FAILURE);
    }
  };

  const handleCancel = () => setOwner(undefined);

  return (
    <Container fluid className="UncleanOwners">
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && !loadingState.loadError &&
        <>
          <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
          <Row>
            <Col md="4" className="clean-nav-col">
              <Card>
                <CardHeader>{county}: {localUnit}</CardHeader>
                <div className="clean-card-scroll">
                  <CustomTable {...tableProps}/>
                </div>
              </Card>
            </Col>
            <Col>
              {owner &&
                <OwnerCard onCancel={handleCancel}
                           onUpdate={handleSave}
                           owner={owner}
                           isEditing={true}
                           showCleanButtons={true}
                           onClean={handleClean}
                           onSkip={handleSkip}/>
              }
            </Col>
          </Row>
        </>
      }
    </Container>
  );
};

export default CleanOwner;