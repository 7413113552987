import {useEffect, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {BreadcrumbsNav, CustomTable, ProgressIndicator, ProgressModal, useAlerts} from '@reasoncorp/kyber-js';

import {CleanedRecord, CleanedRecordReport, CleanedRecordSummary} from '../../types';
import {reportApi} from '../../api';
import {formatDate} from '../../util';
import * as messages from '../../messages';

const CleanedRecordsReport = () => {
  const {year} = useParams<{year: string}>();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, exportingReport: false});
  const [cleanedRecordReport, setCleanedRecordReport] = useState<CleanedRecordReport>({
    cleanedRecords: [],
    cleanedRecordSummaries: []
  });

  useEffect(() => {
    const findCleanedRecords = async () => {
      try {
        const cleanedRecordReport = await reportApi.findCleanedRecords(Number(year));
        setCleanedRecordReport(cleanedRecordReport);
        setLoadingState({loading: false, loadError: false, exportingReport: false});
      } catch (e) {
        showErrorAlert(messages.REPORT_LOAD_FAILURE);
        setLoadingState({loading: false, loadError: true, exportingReport: false});
      }
    };

    findCleanedRecords().then();
  }, [showErrorAlert, year]);

  const tablePropsCleanedRecordsSummaries = {
    headers: [
      {sortKey: 'cleanedByName', title: 'Examiner Name', className: 'w-60'},
      {sortKey: 'count', title: '# of Records Cleaned', className: 'text-center w-40'}
    ],
    items: cleanedRecordReport.cleanedRecordSummaries,
    initialSort: {sortKey: 'cleanedByName', direction: 'asc' as const},
    noResultsMessage: 'No Cleaned Record Examiners available to display in report.',
    renderRow: (cleanedRecordSummary: CleanedRecordSummary) => {
      return <tr>
        <td>
          {cleanedRecordSummary.cleanedByName}
        </td>
        <td className="text-center">
          {cleanedRecordSummary.count}
        </td>
      </tr>;
    },
    renderFooter: () => {
      return <tr>
        <td className="font-weight-bold text-primary">
          Totals
        </td>
        <td className="text-center">
          {cleanedRecordReport.cleanedRecords.length}
        </td>
      </tr>;
    }
  };

  const tablePropsCleanedRecords = {
    headers: [
      {sortKey: 'reasonId', title: 'Reason ID'},
      {sortKey: 'county', title: 'County Name'},
      {sortKey: 'localUnit', title: 'City/Township Name'},
      {sortKey: 'cleanedByName', title: 'Examiner Name', className: 'w-20'},
      {sortKey: 'cleanedAt', title: 'Date', className: 'w-20 text-center'}
    ],
    paginatorConfig: {
      perPage: 100,
      recordName: 'records',
      allowShowAll: true
    },
    items: cleanedRecordReport.cleanedRecords,
    initialSort: {sortKey: 'cleanedAt', direction: 'asc' as const},
    chainSort: true,
    noResultsMessage: 'No Cleaned Records available to display in report.',
    renderRow: (cleanedRecord: CleanedRecord) => {
      return <tr>
        <td>
          {cleanedRecord.reasonId}
        </td>
        <td>
          {cleanedRecord.county}
        </td>
        <td>
          {cleanedRecord.localUnit}
        </td>
        <td>
          {cleanedRecord.cleanedByName}
        </td>
        <td className="text-center">
          {formatDate(cleanedRecord!.cleanedAt)}
        </td>
      </tr>;
    }
  };

  const handleReportDownload = async () => {
    try {
      setLoadingState({
        loadError: false,
        loading: false,
        exportingReport: true
      });
      await reportApi.downloadCleanedRecordsReport(Number(year));
      showSuccessAlert(messages.REPORT_DOWNLOAD_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.REPORT_DOWNLOAD_FAILED);
    } finally {
      setLoadingState({
        loadError: false,
        loading: false,
        exportingReport: false
      });
    }
  };

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator className="mb-4"/>}
    {!loadingState.loading && !loadingState.loadError && <>
      <BreadcrumbsNav breadcrumbs={[
        {text: 'Reports', route: '/reports', icon: 'home' as const},
        {text: `${year} Cleaned Records Report`, active: true}
      ]}/>
      <Row className="mt-2">
        <Col className="d-flex justify-content-end">
          <Button color="primary"
                  onClick={handleReportDownload}
                  disabled={loadingState.loading || loadingState.loadError}>
            Download Report
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <CardHeader>Cleaned Records Examiner Summary</CardHeader>
            <CustomTable {...tablePropsCleanedRecordsSummaries} />
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <CardHeader>Cleaned Records List</CardHeader>
            <CustomTable {...tablePropsCleanedRecords} />
          </Card>
        </Col>
      </Row>
    </>}
    <ProgressModal isOpen={loadingState.exportingReport}
                   title="Generating Cleaned Records Report"
                   content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
  </Container>;
};

export default CleanedRecordsReport;