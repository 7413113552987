import {useEffect, useState} from 'react';
import {Badge, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {useNavigate} from 'react-router-dom';

import {CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {UncleanDetail} from '../types';
import {ownerApi} from '../api';
import * as messages from '../messages';

const Clean = () => {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false
  });
  const {showErrorAlert} = useAlerts();
  const [dirtyRecords, setDirtyRecords] = useState<UncleanDetail[]>([]);
  const [totalDirtyRecords, setTotalDirtyRecords] = useState<number>(0);

  useEffect(() => {
    const loadDirtyOwners = async () => {
      try {
        const dirtyRecords = await ownerApi.findDirty();
        setDirtyRecords(dirtyRecords);

        let sum = 0;
        dirtyRecords.forEach(dirtyRecord => {
          sum += dirtyRecord.count;
        })

        setTotalDirtyRecords(sum);

      } catch (error) {
        showErrorAlert(messages.UNCLEAN_LOAD_FAILURE);
        setLoadingState({loading: false, loadError: true});
      } finally {
        setLoadingState((prevLoadingState) => ({...prevLoadingState, loading: false}));
      }
    };

    loadDirtyOwners().then();
  }, [showErrorAlert]);

  const tableProps = {
    headers: [],
    items: dirtyRecords,
    noResultsMessage: 'No records to clean',
    renderRow: (uncleanDetail: UncleanDetail) => {
      return (
        <tr key={uncleanDetail.county}
            className="cursor-pointer"
            onClick={() => {
              navigate(`/clean/${uncleanDetail.county}`);
            }}>
          <td className="align-left pl-3 w-85">{uncleanDetail.county}</td>
          <td className="text-center">
            <Badge color="dark">{uncleanDetail.count}</Badge>
          </td>
        </tr>
      );
    }
  };

  return (
    <Container fluid>
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && !loadingState.loadError &&
      <Row>
        <Col xs="8" md="4">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  Select County
                </Col>
                <Col className="justify-content-end d-flex">
                  Total {totalDirtyRecords}
                </Col>
              </Row>
            </CardHeader>
            <CustomTable {...tableProps}/>
          </Card>
        </Col>
      </Row>
      }
    </Container>
  );
};

export default Clean;