import {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form, Formik} from 'formik';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';

import {BreadcrumbsNav, FormikSelect, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {reportApi} from '../../api';
import * as messages from '../../messages';

const Reports = () => {
  const navigate = useNavigate();
  const [year, setYear] = useState(2023);
  const [isExportReportDisabled, setIsExportReportDisabled] = useState(false);
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const {showErrorAlert} = useAlerts();

  const years = [
    2023,
    2022,
    2021,
    2020,
    2019,
    2018
  ];

  const cleanedRecordsCardDisabled = year < 2023;
  const disabledCardStyle = {opacity: 0.7, cursor: 'default'};
  const cleanedRecordsCardStyle = cleanedRecordsCardDisabled ? disabledCardStyle : {cursor: 'pointer'};

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setYear(Number(e.target.value));
  };

  // If there are no export batches in a given year, the card should be disabled
  useEffect(() => {
    const loadExportBatches = async () => {
      try {
        const exportBatches = await reportApi.findExportBatches(Number(year));
        setIsExportReportDisabled(exportBatches.length === 0);
        setLoadingState({loadError: false, loading: false});
      } catch (error) {
        setLoadingState({loadError: true, loading: false});
        showErrorAlert(messages.API_FAILURE);
      }
    };

    loadExportBatches().then();
  }, [year, showErrorAlert]);

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator className="mb-4"/>}
    {!loadingState.loading && !loadingState.loadError && <>
      <BreadcrumbsNav breadcrumbs={[
        {text: 'Reports', active: true}
      ]}/>
      <Row className="mt-2">
        <Col>
          <Formik initialValues={{year}}
                  onSubmit={async () => null}>
            {(_) => (
              <Form autoComplete="off" className="mb-0">
                <Row className="d-flex justify-content-between">
                  <Col xs="12" sm="6" md="3" lg="2">
                    <FormikSelect name="year"
                                  labelText="Year"
                                  onChange={handleChange}>
                      {years.map(year => <option value={year}>{year}</option>)}
                    </FormikSelect>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="col-4">
          <Card className="h-100"
                onClick={() => isExportReportDisabled ? () => null : navigate(`/reports/export/${year}`)}
                style={isExportReportDisabled ? disabledCardStyle : {cursor: 'pointer'}}>
            <CardHeader>
              Export Report
            </CardHeader>
            <CardBody>
              YTD Report of Export Lists and Summaries
            </CardBody>
          </Card>
        </Col>
        <Col className="col-4">
          <Card className="h-100"
                style={cleanedRecordsCardStyle}
                onClick={cleanedRecordsCardDisabled ? () => null : () => navigate(`/reports/cleaned-records/${year}`)}>
            <CardHeader>
              Cleaned Records Report
            </CardHeader>
            <CardBody>
              YTD Report Tracking Cleaned Records by Examiner
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>}
  </Container>;
};

export default Reports;