import {auditProApi} from './apiUtils';
import {AuditHistory} from '../types';
import {AuditRequest} from '../types/request';

export const create = (exemptionId: number, auditRequest: AuditRequest): Promise<AuditHistory> =>
  auditProApi.postWithJsonResponse(
    `/exemptions/${exemptionId}/audit-histories/`,
    {
      body: JSON.stringify(auditRequest)
    }
  );

export const remove = (exemptionId: number, auditHistoryId: number) => auditProApi.deleteWithEmptyResponse(
  `/exemptions/${exemptionId}/audit-histories/${auditHistoryId}`
);

export const findByExemptionId = (exemptionId: number): Promise<AuditHistory[]> => auditProApi.getWithJsonResponse(
  `/exemptions/${exemptionId}/audit-histories/`
);